import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Breadcrumbs, ImageListItem, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import { EditProductModal } from "../../modals";
import { useParams } from "react-router-dom";
import { deleteWindowsillProduct } from "@services/queries/windowsill";
import { deleteMosquitoNetsProduct } from "@services/queries/mosquitoNets";
import { deleteDrainagesProduct } from "@services/queries/drainages";
import { deleteAccessoriesProduct } from "@services/queries/accessories";

const StyledIconButton = styled(IconButton)`
    color: ${({ theme }) => theme.palette.secondary.main};
`;

const categoryDeleteActions = {
    windowsill: deleteWindowsillProduct,
    mosquitoNets: deleteMosquitoNetsProduct,
    drainages: deleteDrainagesProduct,
    accessories: deleteAccessoriesProduct
    // Add more conditions for other categories as needed
};

export const Row = (props) => {
    const params = useParams();
    const category = params.page;
    const status = useSelector((state) => state[category]?.status);
    const { provided, style, product } = props;
    const theme = useTheme();
    const [openEditProduct, setOpenEditProduct] = React.useState(false);
    const handleOpenEditProduct = () => setOpenEditProduct(true);
    const handleCloseEditProduct = () => setOpenEditProduct(false);

    const dispatch = useDispatch();
    const handleDelete = (productID) => {
        if (window.confirm('Ви впевнені, що хочете видалити цей продукт?')) {
            if (category && categoryDeleteActions[category]) {
                dispatch(categoryDeleteActions[category](productID));
            }
        }
    };

    if (status === 'loading') {
        return (
            <TableRow>
                <TableCell colSpan={7}>
                    <Skeleton animation="wave" variant="rectangular" height={100} />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <React.Fragment key={product.id}>
            <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={style}>
                <TableCell width='10%'>
                    <StyledIconButton
                        theme={theme}
                        {...provided.dragHandleProps}
                    >
                        <DragIndicatorIcon style={{ color: theme.palette.secondary.main }} />
                    </StyledIconButton>
                </TableCell>
                <TableCell width='10%'>
                    <ImageListItem>
                        <img
                            src={`${product?.image?.url}?timestamp=${Date.now()}`}
                            alt={'item.title'}
                            loading="lazy"
                        />
                    </ImageListItem>
                </TableCell>
                <TableCell width='20%'>
                    <Typography variant="subtitle1">
                        {product.name}
                    </Typography>
                </TableCell>
                <TableCell width='30%'>
                    <Breadcrumbs aria-label="breadcrumb">
                        {Object.values(product.config).map(item => (
                            <div key={item}>
                                <Typography variant="subtitle2">{`${item.type}: ${item.variant} `}</Typography>
                            </div>
                        ))}
                    </Breadcrumbs>
                </TableCell>
                <TableCell component="th" scope="row" width='10%'>
                    {product._id}
                </TableCell>
                <TableCell component="th" scope="row" width='10%'>
                    <StyledIconButton
                        theme={theme}
                        aria-label="expand row"
                        size="small"
                        onClick={handleOpenEditProduct}
                    >
                        <EditIcon style={{ color: theme.palette.success.main }} />
                    </StyledIconButton>

                    <StyledIconButton
                        theme={theme}
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleDelete(product._id)}
                    >
                        <DeleteForeverIcon style={{ color: theme.palette.error.main }} />
                    </StyledIconButton>
                </TableCell>
            </TableRow>
            <EditProductModal product={product} open={openEditProduct} handleClose={handleCloseEditProduct} />
        </React.Fragment>
    );
};

import React, {useEffect, useState} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Row } from './row';
import {getItemStyle} from "./utils";
import {TableHeader} from "./tableHeader";
import {useDispatch} from "react-redux";
import {reorderProducts} from "@services/queries/windowsill";

export const CollapsibleTable = ({ products, category }) => {
    const dispatch = useDispatch();

    const [items, setItems] = useState(products);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedItems = Array.from(items);
        const movedItem = updatedItems[result.source.index];
        updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, movedItem);

        setItems(updatedItems);

        // Send the updated order to the server
        const productOrder = updatedItems.map((product) => product._id);
        dispatch(reorderProducts(productOrder));
    };

    useEffect(() => {
        setItems(products);
    },[products])


    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHeader />

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {products.map((product, index) => (

                                    <Draggable
                                        key={product._id}
                                        draggableId={"q-" + product._id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <Row
                                                 style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                    )}
                                                     category={category}
                                                     provided={provided}
                                                     product={product}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
            </Table>
        </TableContainer>
    );
};


import styled from "@emotion/styled";
import Grid from "@mui/material/Unstable_Grid2";
import {breakpoints, headerHeight} from '@helper/constants';
import Button from "@mui/material/Button";


export const BackToAdmin = styled.div`
  position: fixed;
  top: calc(${headerHeight} + 40px);
  right: 10px;
`;

export const WrapperHeaderContent = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

`;

export const WrapperCategories = styled(Grid)`
    margin: 12px 0;
    width: 100%;
    @media (max-width: ${breakpoints.tablet}) {
        display: flex;
        justify-content: start;
    }
    
    @media (max-width: ${breakpoints.mobile}) {
        display: flex;
        justify-content: center;
    }
 
`;

export const WrapperContainerProducts = styled(Grid)`
    justify-content: center;
    @media (max-width: ${breakpoints.mobile}) {
        display: flex;
    }
`;

export const WrapperProducts = styled(Grid)`
   
    @media (max-width: ${breakpoints.mobile}) {
        width: 100%;
    }
`;

export const FilterButton = styled(Button)`
    width: 160px;
    margin-left: 10px;
   &:hover {
     background-color: ${({ theme }) => theme.palette.secondary.dark} !important;
  }

   
`;

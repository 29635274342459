import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from "react-redux";
import { fetchWindowsillProduct } from "@services/queries/windowsill";
import { fetchMosquitoNetsProduct } from "@services/queries/mosquitoNets";
import { fetchDrainagesProduct } from "@services/queries/drainages";
import { fetchAccessoriesProduct } from "@services/queries/accessories";
import { ProductBlock } from "@features/shared";
import {ProductBlockMoskito} from "@features/shared/ProductBlock";

const categoryDispatchMap = {
    windowsill: fetchWindowsillProduct,
    mosquitoNets: fetchMosquitoNetsProduct,
    drainages: fetchDrainagesProduct,
    accessories: fetchAccessoriesProduct
    // Додайте інші категорії за потреби
};

export const ProductPage = ({ category }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const mosquitoNetsCategory = category === 'mosquitoNets';

    const statusProduct = useSelector((state) => state[category]?.statusProduct);
    const product = useSelector((state) => state[category]?.product);
    const from = state?.from || `/${category}`;
    const handleBackToList = () => navigate(from);

    useEffect(() => {
        if (id && category && categoryDispatchMap[category]) {
            dispatch(categoryDispatchMap[category](id));
        }
    }, [id, category, dispatch]);

    if (statusProduct === 'loading') {
        return <div>Loading...</div>;
    }

    if (!product) {
        return <div>Продукт не знайдено</div>;
    }

    return (
        <>
            <Button startIcon={<ArrowBackIcon />} onClick={handleBackToList}>Список товарів</Button>
            {mosquitoNetsCategory ? <ProductBlockMoskito product={product}/> : <ProductBlock product={product}/>}
        </>
    );
};

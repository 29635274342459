import React, { useState, useEffect } from 'react';
import { TextField, Button, ListItem, ListItemText, FormHelperText } from '@mui/material';

const ulStyle = {
    listStyleType: 'none',
    padding: 0,
    width: "100%",
};
const listStyle = {
    padding: 0,
    width: "100%",
    maxHeight: '30vh',
    overflowY: 'scroll',
};

export const PricesListFixed = ({ prices, setPrices, isPriceValid = true, setIsPriceValid }) => {
    const [newPrice, setNewPrice] = useState('');
    const [error, setError] = useState(false);
    const [priceError, setPriceError] = useState(false);
    const fixedWidth = 1000;

    useEffect(() => {
        if (prices.length === 0) {
            setPrices([{ width: fixedWidth, price: '' }]);
        }
    }, [prices.length, setPrices]);

    const handleAddPrice = () => {
        if (fixedWidth && newPrice) {
            const exists = prices.some(price => Number(price.width) === Number(fixedWidth));
            if (exists) {
                setError(true);
                return;
            }

            const newPriceItem = {
                width: fixedWidth,
                price: newPrice
            };
            setPrices([...prices, newPriceItem]);
            setError(false);
            setNewPrice('');
        }
    };

    const handleEditPrice = (index, key, value) => {
        const newPrices = [...prices];
        newPrices[index] = {
            ...newPrices[index],
            [key]: value
        };
        setPrices(newPrices);
    };

    const validatePrice = () => {
        if (newPrice === '' || isNaN(newPrice)) {
            setPriceError(true);
            setIsPriceValid(false);
        } else {
            setPriceError(false);
            setIsPriceValid(true);
        }
    };

    const textFieldStyle = { margin: '0 8px 0 0', width: '40%' };
    const textFieldPriceStyle = { margin: '0 0 0 0' };
    const inputContainerStyle = { display: 'flex', justifyContent: 'left' };

    return (
        <ul style={ulStyle}>
            <div style={listStyle}>
                {prices.map((price, index) => (
                    <ListItem key={index} component="li" sx={{ padding: 0.2, right: 0 }}>
                        <ListItemText
                            primary={
                                <div style={inputContainerStyle}>
                                    <TextField
                                        style={textFieldStyle}
                                        label="Ціна москітки за м кв"
                                        size="small"
                                        value={price.width}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        disabled // Disable the width input
                                    />
                                    <TextField
                                        style={textFieldPriceStyle}
                                        label="Ціна"
                                        helperText={error  && 'Будь ласка, введіть ціну'}
                                        error={error || !isPriceValid}
                                        size="small"
                                        value={price.price}
                                        onChange={(e) => handleEditPrice(index, 'price', e.target.value)}
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                    />
                                </div>
                            }
                        />
                    </ListItem>
                ))}

            </div>
            {prices.length === 0 && (
                <Button
                    sx={{ marginBottom: '20px' }}
                    onClick={handleAddPrice}
                    disabled={priceError || newPrice === ''} // Disable button if price is invalid
                >
                    Додати ціну
                </Button>
            )}
        </ul>
    );
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store, {history} from './app/store'
import { Provider } from 'react-redux'
import { myTheme } from './app/themes';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
          <ThemeProvider theme={myTheme}>
              {/*<CssBaseline />*/}
                  <App history={history} />
          </ThemeProvider>
      </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import {breakpoints } from '@helper/constants';


export const BasketButton = styled(Button)`
  margin-left: 10px !important;
   &:hover {
     background-color: ${({ theme }) => theme.palette.secondary.dark} !important;
  }

    @media (max-width: ${breakpoints.mobile}) {
        margin-left: 0 !important;
        width: 88%;
        margin-top: 10px;
    }
`;


import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BasketButton } from "@features/shared/BasketBtn/styledComponents";
import { useNavigate } from "react-router-dom";

const BasketBtn = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Адаптуємо до мобільних пристроїв
    const basketItems = useSelector(state => state.basket.items);
    const navigate = useNavigate();
    const goToBasket = () => navigate('/basket');

    return (
        <BasketButton onClick={goToBasket} disabled={!basketItems.length} variant="contained">
            {/*{!isMobile && 'Оформити замовлення'}*/}
            Оформити замовлення
            <ShoppingCartIcon style={{ marginLeft: isMobile ? '0' : '10px' }} />
            <span style={{ marginLeft: '5px' }}>{basketItems.length}</span>
        </BasketButton>
    );
};

export default BasketBtn;

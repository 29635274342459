import {Grid} from "@mui/material";
import {StyledTypographyFilter} from "@features/shared/styledComponents";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {StyledMenuItem} from "@features/shared/ProductBlock/styledComponents";
import React from "react";
import {useTheme} from "@mui/material/styles";


export const TextFieldSelect = ({selectedWidth, handleWidthChange, product}) => {
    const theme = useTheme();

    return <Grid mb={2}>
        <StyledTypographyFilter>оберіть ширину в міліметрах:</StyledTypographyFilter>

        <FormControl sx={{ minWidth: 130, maxWidth: 130 }} size="small">
            <Select
                value={selectedWidth}
                onChange={handleWidthChange}
                sx={{
                    height: '30px',
                    '.MuiSelect-select': { color: theme.palette.primary.main },
                }}>
                {product.price.map((option) => (
                    <StyledMenuItem key={option._id} value={option.width}>
                        {option.width} мм
                    </StyledMenuItem>
                ))}
            </Select>
        </FormControl>
    </Grid>
}
import {blueGrey} from "@mui/material/colors";


export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => {
    return ({
        background: isDragging ? blueGrey[500] : "white",
        ...draggableStyle
    })
};
import { Button, Grid, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createWindowsillProduct } from "@services/queries/windowsill";
import { createMosquitoNetsProduct } from "@services/queries/mosquitoNets";
import { createDrainagesProduct } from "@services/queries/drainages";
import { createAccessoriesProduct } from "@services/queries/accessories";
import { useDispatch, useSelector } from "react-redux";
import { PricesList } from "../shared";
import { useParams } from "react-router-dom";
import { PricesListFixed } from "@features/Admin/content/shared/PriceList";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh', // Додайте максимальну висоту вікна
    overflowY: 'auto',
};

const categoryCreateActions = {
    windowsill: createWindowsillProduct,
    mosquitoNets: createMosquitoNetsProduct,
    drainages: createDrainagesProduct,
    accessories: createAccessoriesProduct,
    // Add more categories if needed
};

export const AddProductModal = ({ open, handleClose, position }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const category = params.page;

    const variants = useSelector((state) => state[category]?.filters);

    const [image, setImage] = useState('');
    const [isImageValid, setIsImageValid] = useState(true);

    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(true);

    const [config, setConfig] = useState(null);

    const [prices, setPrices] = useState([]);
    const [isPriceValid, setIsPriceValid] = useState(true);

    const [additionalPrices, setAdditionalPrices] = useState([]);
    const mosquitoNetsCategory = category === 'mosquitoNets';

    const initialState = () => {
        const updatedData = {};
        if (variants) {
            for (const [key, value] of Object.entries(variants)) {
                const { variants, ...rest } = value;
                updatedData[key] = {
                    ...rest,
                    variant: 'None'
                };
            }
            setConfig(updatedData);
        } else {
            console.error(`Variants not found for category: ${category}`);
        }
    };

    const resetState = () => {
        setName('');
        setPrices([]);
        setImage('');
        setIsNameValid(true);
        initialState();
    };

    useEffect(() => {
        // Очистити конфігурацію при зміні категорії
        setConfig(null);
    }, [category]);


    useEffect(() => {
        if (variants && category) {
            initialState();
        }
    }, [category, variants]);

    const handleNameChange = (event) => {
        setName(event.target.value);
        if (!isNameValid) {
            setIsNameValid(true);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Перевірка розміру файлу
            if (file.size > 2 * 1024 * 1024) {
                setImage(file);
                setIsImageValid(false);
                return;
            }

            setImage(file);
            if (!isImageValid) {
                setIsImageValid(true);
            }
        }
    };

    const handleChangeVariant = (event, prop) => {
        const value = event.target.value;
        const updateConfig = { ...config[prop], variant: value };
        setConfig({ ...config, [prop]: updateConfig });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let valid = true;

        if (name.trim() === '') {
            setIsNameValid(false);
            valid = false;
        }

        if (!prices.length || prices.some(price => price.price === '')) {
            setIsPriceValid(false);
            valid = false;
        }

        if (image === '') {
            setIsImageValid(false);
            valid = false;
        }

        if (!valid) {
            return;
        }

        const formData = new FormData();
        formData.append('category', category);
        formData.append('name', name);
        formData.append('position', position);
        formData.append('config', JSON.stringify(config));
        formData.append('price', JSON.stringify(prices));
        formData.append('additionalPrice', JSON.stringify(additionalPrices));
        formData.append('image', image);
        if (category && categoryCreateActions[category]) {
            dispatch(categoryCreateActions[category](formData));
        }

        resetState();
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box component="form" onSubmit={handleSubmit} sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Додати продукт
                </Typography>
                <TextField
                    sx={{ marginTop: '10px' }}
                    fullWidth={true}
                    id="name"
                    label="Імʼя"
                    value={name}
                    size="small"
                    onChange={handleNameChange}
                    error={!isNameValid}
                    helperText={!isNameValid && 'Будь ласка, введіть імʼя'}
                />
                {mosquitoNetsCategory
                    ? <PricesListFixed category={category} prices={prices} setPrices={setPrices} isPriceValid={isPriceValid} setIsPriceValid={setIsPriceValid} />
                    : <PricesList category={category} prices={prices} setPrices={setPrices} isPriceValid={isPriceValid} setIsPriceValid={setIsPriceValid} />
                }

                <Grid item display='flex' alignItems='center' sx={{ marginTop: 2 }} >
                    <Button color={!isImageValid ? "error" : 'primary'} variant="contained" component="label" size='small' sx={{ marginRight: 1 }}>
                        Додати фото
                        <input onChange={handleImageChange} hidden accept="image/*" multiple type="file" name="images" />
                    </Button>
                    <Typography color={!isImageValid ? "error" : ''} variant="subtitle2">{image.size > 2 * 1024 * 1024 ? 'Розмір файлу перевищує 2 МБ' : image?.name ? image?.name : 'Фото не вибрано'}</Typography>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: 2 }}>

                    {variants && Object.keys(variants)?.map((prop) => {
                        return (
                            <Grid item sx={{ width: '100%' }} key={prop}>
                                <FormControl key={prop} sx={{ width: '100%' }} size="small">
                                    <InputLabel id={`select-${prop}`}>{variants[prop]?.type}</InputLabel>
                                    <Select
                                        labelId={`select-${prop}`}
                                        id={`select-${prop}`}
                                        defaultValue={'None'}
                                        label={variants[prop]?.type}
                                        onChange={(event) => handleChangeVariant(event, prop)}
                                    >
                                        {variants[prop].variants?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value='None'>
                                            None
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid >
                        );
                    })}
                </Grid>

                <PricesList additional prices={additionalPrices} setPrices={setAdditionalPrices} />

                <Grid item display='flex' justifyContent='center' sx={{ marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="success">
                        Додати новий товар <CreateNewFolderIcon sx={{ marginLeft: 1 }} />
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};
import { styled, Typography, Stack, Grid } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

export const NavItem = styled(Grid)`
  display: flex;
  background: ${({colorbtn}) => colorbtn};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: ${({isActive}) => (isActive ? 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none')}; // внутрішня тінь, якщо активний
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out; // Плавна анімація для фону та тіні
  &:hover {
    background: ${({colorhoverbtn}) => colorhoverbtn};
    cursor: pointer;
  }
`;

export const StyledStack = styled(Stack)`
  margin: 10px 0;
`;
export const WrapperFormControl = styled(FormControl)`
    width: 160px;
    display: flex;  
    justify-content: center;
`;
export const WrapperMenuItem = styled(MenuItem)`
    &.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.main} !important;
        color: ${({ theme }) => theme.palette.primary.contrastText} !important;
    }
`;
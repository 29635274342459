import { createSlice } from '@reduxjs/toolkit';
import {
    createDrainagesProduct,
    deleteDrainagesProduct,
    fetchDrainagesFilters,
    fetchDrainagesProducts,
    reorderDrainagesProducts,
    updateDrainagesFilters,
    updateDrainagesProduct,
    fetchDrainagesProduct
} from "../../queries/drainages";

const drainagesSlice = createSlice({
    name: 'drainages',
    initialState: {
        checkedFilters: {},
        products: [],
        product: null,
        filters: null,
        status: 'loading',
        statusProduct: 'loading',
        error: null,
    },
    reducers: {
        setDrainagesCheckedFilters: (state, action) => {
            state.checkedFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDrainagesProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDrainagesProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchDrainagesProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchDrainagesProduct.pending, state => {
                state.statusProduct = 'loading';
            })
            .addCase(fetchDrainagesProduct.fulfilled, (state, action) => {
                state.statusProduct = 'succeeded';
                state.product = action.payload;
            })
            .addCase(fetchDrainagesProduct.rejected, (state, action) => {
                state.statusProduct = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchDrainagesFilters.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDrainagesFilters.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.filters = action.payload;
            })
            .addCase(fetchDrainagesFilters.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteDrainagesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteDrainagesProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = state.products.filter(product => product._id !== action.payload._id);
            })
            .addCase(deleteDrainagesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createDrainagesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createDrainagesProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { _id, config, ...productWithoutConfig } = action.payload.product;
                const newProduct = { ...productWithoutConfig, _id, config };
                state.products.push(newProduct);
            })
            .addCase(createDrainagesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateDrainagesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateDrainagesProduct.fulfilled, (state, action) => {
                const updatedProduct = action.payload.drainagesProduct;
                const updatedProducts = state.products.map((product) =>{
                    return product._id === updatedProduct._id ? updatedProduct : product
                });

                return {
                    ...state,
                    status: 'succeeded',
                    products: updatedProducts,
                };
            })
            .addCase(updateDrainagesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(reorderDrainagesProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(reorderDrainagesProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(reorderDrainagesProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateDrainagesFilters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateDrainagesFilters.fulfilled, (state, action) => {
                state.loading = false;
                state.filters = action.payload;
            })
            .addCase(updateDrainagesFilters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setDrainagesCheckedFilters } = drainagesSlice.actions;

export default drainagesSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { loginClearFailure } from '@services/reducers/user';
import { loginUser } from '@services/queries/user';
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

export const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FormGroup = styled.div`
    margin-bottom: 1rem;
`;

export const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Додаємо navigate
    const theme = useTheme();
    const { error, isLoggedIn, role } = useSelector((state) => state.user);
    const state = useSelector((state) => state);
    const [credentials, setCredentials] = useState({ email: '', password: '' });

    const handleChange = (e) => {
        dispatch(loginClearFailure());
        setCredentials((prevCredentials) => ({ ...prevCredentials, [e.target.name]: e.target.value }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser(credentials));
    };

    useEffect(() => {
        if (isLoggedIn && role === 'admin') {
            navigate('/admin'); // Перенаправлення на адмінку після успішного логіну
        }
    }, [isLoggedIn, role, navigate]);

    const helperText = (type) => error?.type === type && error?.error;
    const errorTextField = (type) => error?.type === type;

    return (
        <Container>
            <StyledTypography as="h2" theme={theme}>Login</StyledTypography>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <TextField
                        error={errorTextField('email')}
                        label="Ім'я користувача"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                        helperText={helperText('email')}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        error={errorTextField('password')}
                        label="Пароль"
                        name="password"
                        type="password"
                        value={credentials.password}
                        onChange={handleChange}
                        helperText={helperText('password')}
                        required
                    />
                </FormGroup>
                <Button
                    disabled={(credentials.email.trim() && credentials.password.trim()) === ''}
                    variant="contained"
                    type="submit"
                >
                    Увійти
                </Button>
            </Form>
        </Container>
    );
};

import axios from "axios";
import Cookies from "js-cookie";
import {
    loginFailure,
    loginSuccess,
    fetchUsersStart,
    fetchUsersSuccess,
    fetchUsersFailure,
} from "@services/reducers/user";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
});

// Екшен-кріятора для логіну
export const loginUser = (credentials) => async (dispatch) => {
    try {
        const response = await api.post('/user/login', credentials);
        dispatch(
            loginSuccess({
                success: response.data.success,
                email: response.data.email,
                token: response.data.token,
                role: response.data.role,
            })
        );
        Cookies.set('token', response.data.token, { expires: 8 / 24 }); // токен зберігається на 8 годин
    } catch(error) {
        console.log('error', error);
        dispatch(loginFailure(error.response.data));
    }
};

// Екшен-кріятора для отримання користувачів
export const fetchUsers = () => async (dispatch) => {
    dispatch(fetchUsersStart());
    try {
        const response = await api.get('/users');
        dispatch(fetchUsersSuccess(response.data));
    } catch (error) {
        dispatch(fetchUsersFailure(error.message));
    }
};


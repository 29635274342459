import {
    DividerStyled,
    StyledFooter,
    WrapperCopyrightStyled,
    WrapperFooterContent,
    WrapperFooterItem
} from "./styledComponents";
import {StyledTypographyFooter} from "./styledComponents";

export const Footer = ({ theme }) => <StyledFooter theme={theme}>
    <WrapperFooterContent>
        <WrapperFooterItem theme={theme}>
            <StyledTypographyFooter theme={theme} bold>адреса:</StyledTypographyFooter>
            <StyledTypographyFooter theme={theme}>Львів.Наукова 43</StyledTypographyFooter>
        </WrapperFooterItem>
        <DividerStyled color={theme.palette.primary.contrastText} orientation="vertical" flexItem />
        <WrapperFooterItem theme={theme} >
            <StyledTypographyFooter theme={theme}>контакти:</StyledTypographyFooter>
            <StyledTypographyFooter theme={theme}>+380678602100</StyledTypographyFooter>
        </WrapperFooterItem>
        <DividerStyled color={theme.palette.primary.contrastText} orientation="vertical" flexItem />
        <WrapperFooterItem theme={theme} >
            <StyledTypographyFooter theme={theme}>графік роботи:</StyledTypographyFooter>
            <StyledTypographyFooter theme={theme}>Пн-Пт: 9:00-18:00</StyledTypographyFooter>
        </WrapperFooterItem>
    </WrapperFooterContent>
    <WrapperCopyrightStyled>
        <StyledTypographyFooter style={{ fontStyle: "italic" }} theme={theme}> Copyright © 2023. Усі права захищені.</StyledTypographyFooter>
    </WrapperCopyrightStyled>

</StyledFooter>
import { Button, Grid, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { updateWindowsillProduct } from "@services/queries/windowsill";
import { updateMosquitoNetsProduct } from "@services/queries/mosquitoNets";
import { updateDrainagesProduct } from "@services/queries/drainages";
import { updateAccessoriesProduct } from "@services/queries/accessories";
import { useDispatch } from "react-redux";
import { PricesList } from "../shared";
import { Properties } from "../collapsibleTable/row/RowCollapse/Properties";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {PricesListFixed} from "@features/Admin/content/shared/PriceList";

const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 20px;
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
    height: 600,
};

const categoryUpdateActions = {
    windowsill: updateWindowsillProduct,
    mosquitoNets: updateMosquitoNetsProduct,
    drainages: updateDrainagesProduct,
    accessories: updateAccessoriesProduct,
    // Add more conditions for other categories as needed
};

export const EditProductModal = ({ open, handleClose, product }) => {
    const params = useParams();
    const category = params.page;
    const dispatch = useDispatch();
    const [image, setImage] = useState('');
    const [name, setName] = useState(product.name || '');
    const [variant, setVariant] = useState(product.config);
    const [prices, setPrices] = useState(product?.price || []);
    const [additionalPrices, setAdditionalPrices] = useState(product?.additionalPrice || []);
    const [isPriceValid, setIsPriceValid] = useState(true);
    const mosquitoNetsCategory= category === 'mosquitoNets';

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    if (!prices.length) {
        setIsPriceValid(false);
    }

    const onSave = () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        formData.append('config', JSON.stringify(variant));
        formData.append('price', JSON.stringify(prices));
        formData.append('additionalPrice', JSON.stringify(additionalPrices));

        const updateProduct = {
            id: product._id,
            formData: formData,
        };

        if (category && categoryUpdateActions[category]) {
            dispatch(categoryUpdateActions[category](updateProduct));
        }
        // Add more conditions for other categories as needed

        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box component="form" onSubmit={onSave} sx={style}>
                <Grid container>
                    <Grid item xs={4} style={{ paddingRight: '16px' }}>
                        <StyledTypography as="h2">
                            Зображення
                        </StyledTypography>
                        <img
                            src={image ? URL.createObjectURL(image) : `${product?.image?.url}?timestamp=${Date.now()}`}
                            alt={'item.title'}
                            loading="lazy"
                            width='100%'
                            key={product.id}
                        />
                        <Grid item mt={2} display='flex' justifyContent='center'>
                            <Button variant="contained" component="label" size='small'>
                                Завантажити
                                <input hidden accept="image/*" multiple type="file" name="images" onChange={(event) => setImage(event.target.files[0])} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StyledTypography as="h2">
                            Інфо продукта
                        </StyledTypography>
                        <Grid container spacing={2}>
                            <Grid item>
                                <TextField
                                    id="outlined-controlled"
                                    label="Ім'я"
                                    value={name}
                                    size="small"
                                    onChange={handleNameChange}
                                />
                            </Grid>
                            <Grid item>
                                <Properties
                                    config={Object.values(product.config)}
                                    variant={variant}
                                    setVariant={setVariant}
                                />
                                <StyledTypography as="h2">
                                    Додаткові послуги
                                </StyledTypography>
                                <PricesList additional prices={additionalPrices} setPrices={setAdditionalPrices} setIsPriceValid={setIsPriceValid} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StyledTypography as="h2">
                            Формування ціни
                        </StyledTypography>
                        {mosquitoNetsCategory
                            ? <PricesListFixed category={category} prices={prices} setPrices={setPrices} isPriceValid={isPriceValid} setIsPriceValid={setIsPriceValid} />
                            : <PricesList category={category} prices={prices} setPrices={setPrices} isPriceValid={isPriceValid} setIsPriceValid={setIsPriceValid} />
                        }                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent='center' marginTop='16px'>
                    <Button variant="contained" color="success" onClick={onSave}>
                        Зберегти зміни
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

import { createSlice } from '@reduxjs/toolkit';
import {
    createWindowsillProduct,
    deleteWindowsillProduct,
    fetchWindowsillFilters,
    fetchWindowsillProducts,
    reorderProducts,
    updateWindowsillFilters,
    updateWindowsillProduct,
    fetchWindowsillProduct
} from "../../queries/windowsill";


const windowsillSlice = createSlice({
    name: 'windowsill',
    initialState: {
        checkedFilters: {},
        products: [],
        product: null,
        filters: null,
        status: 'loading',
        statusProduct: 'loading',
        error: null,
    },
    reducers: {
        setWindowsillCheckedFilters: (state, action) => {
            state.checkedFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
    builder
        .addCase(fetchWindowsillProducts.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchWindowsillProducts.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.products = action.payload;
        })
        .addCase(fetchWindowsillProducts.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchWindowsillProduct.pending, state => {
            state.statusProduct = 'loading';
        })
        .addCase(fetchWindowsillProduct.fulfilled, (state, action) => {
            state.statusProduct = 'succeeded';
            state.product = action.payload;
        })
        .addCase(fetchWindowsillProduct.rejected, (state, action) => {
            state.statusProduct = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchWindowsillFilters.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchWindowsillFilters.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.filters = action.payload;
        })
        .addCase(fetchWindowsillFilters.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(deleteWindowsillProduct.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(deleteWindowsillProduct.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.products = state.products.filter(product => product._id !== action.payload._id);
        })
        .addCase(deleteWindowsillProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(createWindowsillProduct.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(createWindowsillProduct.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const { _id, config, ...productWithoutConfig } = action.payload.product;

            const newProduct = { ...productWithoutConfig, _id, config };
            state.products.push(newProduct);
        })
        .addCase(createWindowsillProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(updateWindowsillProduct.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(updateWindowsillProduct.fulfilled, (state, action) => {
            const updatedProduct = action.payload.windowsillProduct;
            const updatedProducts = state.products.map((product) =>{
                return product._id === updatedProduct._id ? updatedProduct : product
            }

            );
            return {
                ...state,
                status: 'succeeded',
                products: updatedProducts,
            };
        })
        .addCase(updateWindowsillProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(reorderProducts.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(reorderProducts.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
        .addCase(reorderProducts.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        }).addCase(updateWindowsillFilters.pending, (state) => {
            state.loading = true;
            state.error = null;
        }).addCase(updateWindowsillFilters.fulfilled, (state, action) => {
            state.loading = false;
            state.filters = action.payload;
        }).addCase(updateWindowsillFilters.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


}
    ,
});

export const { setWindowsillCheckedFilters } = windowsillSlice.actions;

export default windowsillSlice.reducer;

import React, {useEffect, useState} from 'react';
import {Grid, RadioGroup, FormControlLabel, Radio, Button, Typography, useTheme} from '@mui/material';
import { StyledTypographyFilter } from "@features/shared/styledComponents";
import {setDeliveryCharge} from "@services/reducers/basketSlice";
import {useDispatch, useSelector} from "react-redux";

export const BasketPayment = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const totalPrice = useSelector(state => state.basket.totalPrice);

    const theme = useTheme()
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'delivery') {
            dispatch(setDeliveryCharge(100));
        } else {
            dispatch(setDeliveryCharge(0));
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <RadioGroup row aria-label="delivery" name="delivery" value={selectedValue} onChange={handleChange}>
                    <FormControlLabel
                        value="pickup"
                        control={<Radio />}
                        label={<Typography color={theme.palette.primary.main} variant="body1">Самовивіз з адреси: Львів, вул. Наукова 43</Typography>}
                    />
                    <FormControlLabel
                        value="delivery"
                        control={<Radio />}
                        label={<Typography color={theme.palette.primary.main} variant="body1">Доставка в межах Львову (100грн)</Typography>}
                    />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <StyledTypographyFilter>Всього до оплати:</StyledTypographyFilter>
                <StyledTypographyFilter bold>{`${totalPrice} грн`} </StyledTypographyFilter>
                <Button variant="contained" color="secondary">
                    Оплатити
                </Button>
            </Grid>
        </Grid>
    );
};

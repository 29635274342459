import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { LoginForm } from "../Login";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "./header";
import { Nav } from "./nav";
import { Content } from "./content";
import Box from "@mui/material/Box";
import { categoryFetchActions } from "@helper/constants";

const AdminBlockWrapper = styled.div``;

export const Admin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const role = useSelector((state) => state.user.role);
    const token = Cookies.get('token');
    const products = useSelector((state) => state[params.page]?.products) || [];
    const status = useSelector((state) => state[params.page]?.status);
    const error = useSelector((state) => state[params.page]?.error);
    const [hasRender, setHasRender] = useState(false);
    const [open, setOpen] = useState(true);
    const categories = Object.keys(categoryFetchActions);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (token && role === 'admin') {
            setHasRender(true);
            if (!params?.page || !categories.includes(params?.page)) {
                navigate('/admin/mosquitoNets', { replace: true });
            }
        } else {
            setHasRender(false);
        }
    }, [token, role, params.page, navigate, categories]);

    useEffect(() => {
        if (!token || role !== 'admin') {
            navigate('/login', { replace: true }); // Redirect to login page if the user is not an admin
        }
    }, [token, role, navigate]);

    useEffect(() => {
        if (params.page && categoryFetchActions[params.page]) {
            dispatch(categoryFetchActions[params.page].fetchProducts());
            dispatch(categoryFetchActions[params.page].fetchFilters());
        }
    }, [dispatch, params.page]);

    if (status === 'failed') {
        return <div>{error}</div>;
    }

    return (
        <AdminBlockWrapper>
            {hasRender && (
                <Box sx={{ display: 'flex', mt: 3 }}>
                    <Header navigate={navigate} open={open} handleDrawerOpen={handleDrawerOpen} />
                    <Nav open={open} handleDrawerClose={handleDrawerClose} />
                    <Content category={params.page} products={products} />
                </Box>
            )}
            {!hasRender && <LoginForm />}
        </AdminBlockWrapper>
    );
};

export default Admin;

import Box from "@mui/material/Box";
import {CollapsibleTable} from "./collapsibleTable";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import {useParams} from "react-router-dom";
import {navList} from "@helper/constants";
import {Button, Grid} from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {AddProductModal, EditFiltersModal} from "./modals";

const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 20px;
`;

export const Content = ({ products, category }) => {
    const [openAddProduct, setOpenAddProduct] = React.useState(false);
    const [openEditFilters, setEditFilters] = React.useState(false);

    const handleOpenAddProduct = () => setOpenAddProduct(true);
    const handleOpenEditFilters = () => setEditFilters(true);
    const handleCloseAddProduct = () => setOpenAddProduct(false);
    const handleCloseEditFilters = () => setEditFilters(false);
    const params = useParams();

    // Перевірка наявності params.page та знайденого елемента в navList
    const navItem = params.page ? navList.find(item => item.page === params.page) : null;
    const title = navItem ? navItem.title : "Сторінка не знайдена";

    if (!category) {
        return (
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 4 }}>
                <div>сторінку не знайдено</div>
            </Box>
        )
    }

    return (
        <Grid component="main" sx={{ flexGrow: 1, p: 3, mt: 2 }}>
            <Grid sx={{ display: 'flex' }}>
                <StyledTypography as="h2">
                    {title}
                </StyledTypography>
                <Grid sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <Button onClick={handleOpenEditFilters} variant="contained" color="success" sx={{ margin: 2 }}>
                        Редагувати фільтри <ReceiptIcon sx={{ marginLeft: 1 }} />
                    </Button>
                    <Button onClick={handleOpenAddProduct} variant="contained" color="success">
                        Додати товар <CreateNewFolderIcon sx={{ marginLeft: 1 }} />
                    </Button>
                </Grid>
            </Grid>

            {products.length ? <CollapsibleTable products={products} category={category} /> : <div>Немає товарів для відображення</div>}
            <AddProductModal position={products.length > 0 ? products[products.length - 1].position + 1 : 1} open={openAddProduct} handleClose={handleCloseAddProduct} />
            <EditFiltersModal open={openEditFilters} handleClose={handleCloseEditFilters} />
        </Grid>
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
});

export const reorderAccessoriesProducts = createAsyncThunk(
    'accessories/reorder',
    async (productOrder, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.put('/accessories/products/reorder', { productOrder });
            dispatch(fetchAccessoriesProducts());
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createAccessoriesProduct = createAsyncThunk(
    'accessories/createProduct',
    async (product, { rejectWithValue }) => {
        try {
            const response = await api.post('/accessories/products', product);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAccessoriesProduct = createAsyncThunk(
    'accessories/updateProduct',
    async (updateProduct, { rejectWithValue }) => {
        const { id, formData } = updateProduct;
        if (!id || !formData) {
            return rejectWithValue('Invalid product object: missing id or formData');
        }

        try {
            const response = await api.put(`/accessories/products/${id}`, formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchAccessoriesProducts = createAsyncThunk(
    'accessories/fetchProducts',
    async () => {
        const response = await api.get('/accessories/products');
        const products = response.data.map(product => {
            const { _id, config, ...productWithoutConfig } = product;
            const configKeys = Object.keys(config);
            const newConfig = configKeys.map(key => ({
                key,
                ...config[key]
            }));
            return { ...productWithoutConfig, _id, config: newConfig };
        });

        // Сортування за полем "position"
        products.sort((a, b) => a.position - b.position);

        return products;
    }
);

export const fetchAccessoriesProduct = createAsyncThunk(
    'accessories/fetchProduct',
    async (id) => {
        const response = await api.get(`/accessories/products/${id}`);
        const product = response.data;
        return product;
    }
);

export const deleteAccessoriesProduct = createAsyncThunk(
    'accessories/deleteProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/accessories/products/${productId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchAccessoriesFilters = createAsyncThunk(
    'accessories/fetchFilters',
    async () => {
        const response = await api.get('/accessories/filters');

        const updatedData = {};
        for (const [key, value] of Object.entries(response.data)) {
            const { _id, ...rest } = value;
            updatedData[key] = rest;
        }

        return updatedData;
    }
);

export const updateAccessoriesFilters = createAsyncThunk(
    'accessories/updateFilters',
    async ({ field, variants }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/accessories/filters/${field}/variants`, { variants });
            const { category, __v, _id, ...filteredData } = response.data;
            return filteredData;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

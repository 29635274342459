import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { drawerWidth } from "../../../helper/constants";
import {logout} from "@services/reducers/user";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  text-transform: lowercase;
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.main};
    };
`;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    display: 'flex',
    paddingRight: '12px',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
export const Header = (props) => {
    const { handleDrawerOpen, open } = props;
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const onHandlerLogout = () => dispatch(logout());
    return (
        <AppBar open={open}>
            <Box sx={{ display: 'flex' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <StyledTypography variant="h6">
                        Адмін панель
                    </StyledTypography>
                </Toolbar>
                <Box sx={{display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <StyledButton onClick={() => navigate('/mosquitoNets')} color="secondary" >Москітні сітки</StyledButton>
                    <StyledButton onClick={() => navigate('/windowsill')} color="secondary" >Підвіконня</StyledButton>
                    <StyledButton onClick={() => navigate('/drainages')} color="secondary" >Водовідводи</StyledButton>
                    <StyledButton onClick={() => navigate('/accessories')} color="secondary" >Аксесуари</StyledButton>
                    <StyledButton color="secondary" variant="outlined" onClick={onHandlerLogout}>Вийти</StyledButton>
                </Box>
            </Box>
        </AppBar>
    );
};




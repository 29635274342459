import {styled, Typography} from "@mui/material";
import {css} from "@mui/system";


export const StyledTypographyFilter = styled(({ bold, ...other }) => <Typography {...other} />)(
    ({ theme, bold }) => css({
        color: theme.palette.primary.main,
        padding: "0 4px",
        fontWeight: bold ? 700 : 400, // 700 для жирного шрифту, 400 для звичайного
    }),
);
import styled from "@emotion/styled";
import {breakpoints, footerHeight} from "@helper/constants";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import {css} from "@mui/system";
import Divider from "@mui/material/Divider";


export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: ${footerHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  margin-top: 10px;
    @media (max-width: ${breakpoints.mobile}) {
        height: auto;

    }
`;

export const WrapperFooterItem = styled(Grid)`
  margin: 0 10px;

    @media (max-width: ${breakpoints.mobile}) {
        flex: 0 0 100%; // Задає фіксовану ширину для кожного айтема, забезпечуючи, щоб два айтема поміщалися в один рядок
        max-width: 100%; 
        margin-top: 2px;
        margin-bottom: 2px;
        text-align: center;
    }
`;

export const DividerStyled = styled(Divider)`

    @media (max-width: ${breakpoints.mobile}) {
      display: none;
    }
`;

export const WrapperFooterContent = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; // Це забезпечить рівномірний простір між айтемами
    }
`;

export const WrapperCopyrightStyled = styled(Grid)`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    @media (max-width: ${breakpoints.mobile}) {
        justify-content: center;
    }
`;

export const StyledTypographyFooter = styled(({ bold, ...other }) => <Typography {...other} />)(
    ({ theme, bold }) => css({
        color: theme.palette.primary.contrastText,
        padding: "0 4px",
        fontWeight: bold ? 700 : 400, // 700 для жирного шрифту, 400 для звичайного
    }),
);
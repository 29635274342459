import {FormHelperText, Grid, InputAdornment, TextField} from "@mui/material";
import {StyledTypographyFilter} from "@features/shared/styledComponents";
import React from "react";
import {useTheme} from "@mui/material/styles";



export const TextFieldInputCount = ({
            selectedCount= 1,
            handleCountChange,
            lengthError,
            getSelectedLengthError,
            title=''
    }) => {
    const theme = useTheme();

    return <Grid>
        <StyledTypographyFilter>{title}</StyledTypographyFilter>
        <TextField
            size="small"
            sx={{
                minWidth: 130,
                maxWidth: 130,
                '.MuiOutlinedInput-root': { height: '30px' },
                '.MuiInputBase-input': { color: theme.palette.primary.main }
            }}
            type="number"
            value={selectedCount}
            onChange={handleCountChange}
            variant="outlined"
            error={Boolean(lengthError)}
            inputProps={{
                min: 1,
                max: 1000,
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">шт.</InputAdornment>,
            }}
        />
        <FormHelperText error={Boolean(lengthError)}>
            {Boolean(lengthError) && getSelectedLengthError(selectedCount)}
        </FormHelperText>
    </Grid>
}


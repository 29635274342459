import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Grid from "@mui/material/Unstable_Grid2";
import {
    BackToAdmin, FilterButton, WrapperCategories, WrapperContainerProducts, WrapperProducts,
} from "./styledComponents";
import {
    Accordion, AccordionDetails, AccordionSummary, Container, Dialog, DialogActions, DialogContent, useMediaQuery
} from "@mui/material";
import { ProductList, ProductsNavigation, Filter } from "@features/shared";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ProductPage } from "@features/ProductPage";
import Button from "@mui/material/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import FilterListIcon from '@mui/icons-material/FilterList';
import Typography from "@mui/material/Typography";
import { NoResultsMessage } from "@features/NoResultsMessage";
import { categoryFetchActions } from "@helper/constants";
import { setWindowsillCheckedFilters } from "@services/reducers/windowsill";
import { setAccessoriesCheckedFilters } from "@services/reducers/accessories";
import { setDrainagesCheckedFilters } from "@services/reducers/drainages";
import { setMosquitoNetsCheckedFilters } from "@services/reducers/mosquitoNets";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const listCategory = ['mosquitoNets', 'windowsill', 'drainages', 'accessories'];

const getCategoryFromPage = (page) => {
    const category = page.replace(/^\/|\/$/g, ''); // Remove leading and trailing slashes
    return listCategory.includes(category) ? category : null;
};

const dispatchSetCheckedFilters = {
    mosquitoNets: setMosquitoNetsCheckedFilters,
    windowsill: setWindowsillCheckedFilters,
    drainages: setDrainagesCheckedFilters,
    accessories: setAccessoriesCheckedFilters,
};

export const Home = ({ title = 'Home', onClick }) => {
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState('/mosquitoNets');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const dispatch = useDispatch();
    const category = useMemo(() => getCategoryFromPage(currentPage), [currentPage]);
    const checkedFilters = useSelector((state) => state[category]?.checkedFilters || {}); // отримання стану фільтрів для поточної категорії

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const matchesSM = useMediaQuery(theme.breakpoints.up('md'));
    const token = Cookies.get('token');
    const navigate = useNavigate();

    const products = useSelector((state) => state[category]?.products) || [];
    const filters = useSelector((state) => state[category]?.filters);
    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentPage(`/${newValue}`);
        navigate(`/${newValue}`);
    };

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setCurrentPage(`/${newValue}`);
        navigate(`/${newValue}`);
    };

    const handleOpenFilters = () => {
        setOpen(true);
    };

    const handleCloseFilters = () => {
        setOpen(false);
    };

    const handleBackToAdmin = () => navigate('/Admin/mosquitoNets');

    const itemsPerPage = 12;
    const totalPages = useMemo(() => {
        const isAnyFilterChecked = Object.values(checkedFilters).some(filterArray => filterArray.length > 0);
        let newFilteredProducts;

        if (isAnyFilterChecked) {
            newFilteredProducts = products.filter(product => {
                return product.config.some(configItem => {
                    return checkedFilters[configItem.key] && checkedFilters[configItem.key].includes(configItem.variant);
                });
            });
        } else {
            newFilteredProducts = products;
        }
        return Math.ceil(newFilteredProducts.length / itemsPerPage);
    }, [products, checkedFilters, itemsPerPage]);
    const configKeys = filters && Object.keys(filters);

    useEffect(() => {
        if (category && categoryFetchActions[category]) {
            dispatch(categoryFetchActions[category].fetchProducts());
            dispatch(categoryFetchActions[category].fetchFilters());
        }
    }, [dispatch, category]);

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/mosquitoNets');
            return;
        }

        if (location.pathname !== currentPage) {
            setCurrentPage(location.pathname);
        }
    }, [location.pathname, currentPage, navigate]);

    useEffect(() => {
        if (products && filters) {
            const isAnyFilterChecked = Object.values(checkedFilters).some(filterArray => filterArray.length > 0);
            let newFilteredProducts;

            if (isAnyFilterChecked) {
                newFilteredProducts = products.filter(product => {
                    return product.config.some(configItem => {
                        return checkedFilters[configItem.key] && checkedFilters[configItem.key].includes(configItem.variant);
                    });
                });
            } else {
                newFilteredProducts = products;
            }

            setFilteredProducts(newFilteredProducts);
        }
    }, [products, filters, checkedFilters]);

    const { id } = useParams();
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let productsToDisplay = filteredProducts.slice(startIndex, endIndex);

    // Заповнення порожніми елементами, якщо товарів менше 12
    if (productsToDisplay.length < itemsPerPage) {
        productsToDisplay = [
            ...productsToDisplay,
            ...Array(itemsPerPage - productsToDisplay.length).fill({ empty: true })
        ];
    }

    return (
        <Grid container direction="column" style={{ minHeight: '100vh' }}>
            <Grid container style={{ flex: 1 }}>
                <Container maxWidth="xl">
                    {!id ? (
                        <>
                            <WrapperCategories direction="row" wrap="wrap">
                                {matchesSM ? (
                                    <Tabs
                                        value={currentPage.replace('/', '')}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        <Tab label="Москітні сітки" value="mosquitoNets" />
                                        <Tab label="Підвіконня" value="windowsill" />
                                        <Tab label="Водовідливи" value="drainages" />
                                        <Tab label="Аксесуари" value="accessories" />
                                    </Tabs>
                                ) : (
                                    <Select
                                        value={currentPage.replace('/', '')}
                                        onChange={handleSelectChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="mosquitoNets">Москітні сітки</MenuItem>
                                        <MenuItem value="windowsill">Підвіконня</MenuItem>
                                        <MenuItem value="drainages">Водовідливи</MenuItem>
                                        <MenuItem value="accessories">Аксесуари</MenuItem>
                                    </Select>
                                )}
                                {!matchesSM && (
                                    <FilterButton onClick={handleOpenFilters} variant="contained">
                                        <FilterListIcon />
                                        Фільтр
                                    </FilterButton>
                                )}
                            </WrapperCategories>

                            <WrapperContainerProducts container spacing={2} direction="row" wrap="wrap">
                                {matchesSM && (
                                    <Grid xs={4} sm={2} md={2}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography>Фільтри</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Filter
                                                    configKeys={configKeys}
                                                    filters={filters}
                                                    checkedFilters={checkedFilters}
                                                    setCheckedFilters={(filters) => dispatch(dispatchSetCheckedFilters[category](filters))}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}

                                <WrapperProducts xs={8} sm={10}>
                                    {productsToDisplay.length > 0 ? (
                                        <>
                                            <ProductList products={productsToDisplay} category={category} />
                                            <Stack spacing={2} sx={{ marginTop: "10px", marginBottom: "10px", justifyContent: "center" }}>
                                                <Pagination
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    count={totalPages}
                                                    page={page}
                                                    onChange={handleChange}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                            {...item}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </>
                                    ) : (
                                        <NoResultsMessage />
                                    )}
                                </WrapperProducts>
                            </WrapperContainerProducts>
                            <Dialog open={open} onClose={handleCloseFilters}>
                                <DialogContent>
                                    <Filter
                                        configKeys={configKeys}
                                        filters={filters}
                                        checkedFilters={checkedFilters}
                                        setCheckedFilters={(filters) => dispatch(dispatchSetCheckedFilters[category](filters))}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseFilters}>Close</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    ) : (
                        <ProductPage category={category} />
                    )}
                </Container>
            </Grid>

            {token && (
                <BackToAdmin>
                    <Button color="primary" variant="outlined" onClick={handleBackToAdmin}>
                        <KeyboardDoubleArrowRightIcon />
                        Адмін
                    </Button>
                </BackToAdmin>
            )}
        </Grid>
    );
};
import { createSlice } from '@reduxjs/toolkit';
import {
    createAccessoriesProduct,
    deleteAccessoriesProduct,
    fetchAccessoriesFilters,
    fetchAccessoriesProducts,
    reorderAccessoriesProducts,
    updateAccessoriesFilters,
    updateAccessoriesProduct,
    fetchAccessoriesProduct
} from "../../queries/accessories";

const accessoriesSlice = createSlice({
    name: 'accessories',
    initialState: {
        checkedFilters: {},
        products: [],
        product: null,
        filters: null,
        status: 'loading',
        statusProduct: 'loading',
        error: null,
    },
    reducers: {
        setAccessoriesCheckedFilters: (state, action) => {
            state.checkedFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccessoriesProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccessoriesProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchAccessoriesProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAccessoriesProduct.pending, state => {
                state.statusProduct = 'loading';
            })
            .addCase(fetchAccessoriesProduct.fulfilled, (state, action) => {
                state.statusProduct = 'succeeded';
                state.product = action.payload;
            })
            .addCase(fetchAccessoriesProduct.rejected, (state, action) => {
                state.statusProduct = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAccessoriesFilters.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccessoriesFilters.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.filters = action.payload;
            })
            .addCase(fetchAccessoriesFilters.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteAccessoriesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteAccessoriesProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = state.products.filter(product => product._id !== action.payload._id);
            })
            .addCase(deleteAccessoriesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createAccessoriesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAccessoriesProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { _id, config, ...productWithoutConfig } = action.payload.product;
                const newProduct = { ...productWithoutConfig, _id, config };
                state.products.push(newProduct);
            })
            .addCase(createAccessoriesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateAccessoriesProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAccessoriesProduct.fulfilled, (state, action) => {
                const updatedProduct = action.payload.accessoriesProduct;
                const updatedProducts = state.products.map((product) =>{
                    return product._id === updatedProduct._id ? updatedProduct : product
                });

                return {
                    ...state,
                    status: 'succeeded',
                    products: updatedProducts,
                };
            })
            .addCase(updateAccessoriesProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(reorderAccessoriesProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(reorderAccessoriesProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(reorderAccessoriesProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateAccessoriesFilters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateAccessoriesFilters.fulfilled, (state, action) => {
                state.loading = false;
                state.filters = action.payload;
            })
            .addCase(updateAccessoriesFilters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setAccessoriesCheckedFilters } = accessoriesSlice.actions;

export default accessoriesSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
});

export const reorderMosquitoNetsProducts = createAsyncThunk(
    'mosquitoNets/reorder',
    async (productOrder, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.put('/mosquitoNets/products/reorder', { productOrder });
            dispatch(fetchMosquitoNetsProducts());
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createMosquitoNetsProduct = createAsyncThunk(
    'mosquitoNets/createProduct',
    async (product, { rejectWithValue }) => {
        try {
            const response = await api.post('/mosquitoNets/products', product);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateMosquitoNetsProduct = createAsyncThunk(
    'mosquitoNets/updateProduct',
    async (updateProduct, { rejectWithValue }) => {
        const { id, formData } = updateProduct;
        if (!id || !formData) {
            return rejectWithValue('Invalid product object: missing id or formData');
        }

        try {
            const response = await api.put(`/mosquitoNets/products/${id}`, formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchMosquitoNetsProducts = createAsyncThunk(
    'mosquitoNets/fetchProducts',
    async () => {
        const response = await api.get('/mosquitoNets/products');
        const products = response.data.map(product => {
            const { _id, config, ...productWithoutConfig } = product;
            const configKeys = Object.keys(config);
            const newConfig = configKeys.map(key => ({
                key,
                ...config[key]
            }));
            return { ...productWithoutConfig, _id, config: newConfig };
        });

        // Сортування за полем "position"
        products.sort((a, b) => a.position - b.position);

        return products;
    }
);

export const fetchMosquitoNetsProduct = createAsyncThunk(
    'mosquitoNets/fetchProduct',
    async (id) => {
        const response = await api.get(`/mosquitoNets/products/${id}`);
        const product = response.data;
        return product;
    }
);

export const deleteMosquitoNetsProduct = createAsyncThunk(
    'mosquitoNets/deleteProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/mosquitoNets/products/${productId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchMosquitoNetsFilters = createAsyncThunk(
    'mosquitoNets/fetchFilters',
    async () => {
        const response = await api.get('/mosquitoNets/filters');

        const updatedData = {};
        for (const [key, value] of Object.entries(response.data)) {
            const { _id, ...rest } = value;
            updatedData[key] = rest;
        }

        return updatedData;
    }
);

export const updateMosquitoNetsFilters = createAsyncThunk(
    'mosquitoNets/updateFilters',
    async ({ field, variants }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/mosquitoNets/filters/${field}/variants`, { variants });
            const { category, __v, _id, ...filteredData } = response.data;
            return filteredData;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

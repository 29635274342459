import styled from "@emotion/styled";
import CardContent from "@mui/material/CardContent";
import Grid from '@mui/material/Grid';
import {Typography} from "@mui/material";
import {breakpoints} from "@helper/constants";

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
    margin: 0;
`;

export const StyledTitlePrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
    margin: 0;
`;

export const ProductInfo = styled(CardContent)`
    overflow-y: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    position: absolute; // Додаємо абсолютне позиціонування
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9); // Додаємо фон, щоб текст був читабельним
`;


export const AnimatedGridContainer = styled(Grid)`
  animation: slideFromRight 0.6s ease-out;

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

    @media (max-width: ${breakpoints.mobile}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const WrapperProduct = styled(Grid)`
    
    @media (max-width: ${breakpoints.mobile}) {
       width: 100%;
    }
`;

export const StyledBox = styled('div')`
    background: lightblue;
    border-radius: 4px;
    position: relative; // важливо для відносного позиціонування

    &:hover .product-info {
        max-height: 200px; // або інше значення, яке підходить для вашого контенту
    }
   
`;

export const StyledEmptyBox = styled('div')`
  width: 100%;
  height: 352px;
  background: #edeff1a6;
  borderRadius: 4px;
  paddingLeft: 0;

    @media (max-width: ${breakpoints.mobile}) {
        width: 100%;
    }
  

`;
import { createTheme } from "@mui/material/styles";
import {blueGrey, lightGreen} from "@mui/material/colors";

export const myTheme = createTheme({
    palette: {
        primary: {
            main: blueGrey[700],
            contrastText: blueGrey[50],
        },
        secondary: {
            main: lightGreen[700],
            dark: lightGreen[900],
            contrastText: lightGreen[50],
        },
        hover: {
            main: '#BED5DD',
        },
        background: {
            default: '#d53e3e',
            paper: blueGrey[50],
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1000,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Montserrat, Arial, sans-serif',
        h1: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 700,
        },
        h2: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 600,
        },
        h6: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '1.25rem', // ваш розмір шрифту для h6
            lineHeight: '1.75rem', // ваша висота рядка для h6
        },
        body1: {
            fontFamily: 'Montserrat, Arial, sans-serif',
            fontWeight: 400,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        border: `1px solid #828282`,
                    },
                },
                {
                    props: { variant: 'outlined', color: 'secondary' },
                    style: {
                        border: `1px solid ${blueGrey[50]}`,
                        color: blueGrey[50],
                        '&:hover': {
                            color: lightGreen[700],
                        },
                    },
                },
            ],
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    // ваші стилі
                },
                icon: {
                    color: blueGrey[700],
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: blueGrey[700],
                        color: blueGrey[50],
                        '&:hover': {
                            backgroundColor: blueGrey[600],
                        },
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    color: blueGrey[900],
                    fontSize: '0.9rem', // розмір заголовка
                    fontWeight: 'bold', // вага шрифту для заголовка
                },
                subheader: {
                    color: blueGrey[900],
                    fontSize: '1rem', // розмір підзаголовка
                },
            },
        },
    },
});
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from "react-redux";
import { removeItem, updateItem} from "@services/reducers/basketSlice";

export const BasketTable = () => {
    const basketItems = useSelector(state => state.basket.items);
    const dispatch = useDispatch();

    const removeItemFromBasket = (uniqueId) => {
        dispatch(removeItem({ uniqueId }));
    };

    const incrementQuantity = (item) => {
        const newItem = {
            ...item,
            quantity: item.quantity + 1,
            finalPrice: (item.finalPrice / item.quantity) * (item.quantity + 1),
        };
        dispatch(updateItem(newItem));
    };

    const decrementQuantity = (item) => {
        if (item.quantity > 1) {
            const newItem = {
                ...item,
                quantity: item.quantity - 1,
                finalPrice: (item.finalPrice / item.quantity) * (item.quantity - 1),
            };
            dispatch(updateItem(newItem));
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Зображення</TableCell>
                        <TableCell>Назва</TableCell>
                        <TableCell>Ціна</TableCell>
                        <TableCell>Опис</TableCell>
                        <TableCell>Кількість</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {basketItems.map((item) => (
                        <TableRow key={item.uniqueId}>
                            <TableCell><img src={item.img} alt={item.name} style={{ width: '50px' }} /></TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{`${item.finalPrice} грн`}</TableCell>
                            <TableCell>
                                {`Довжина: ${item.selectedLength} мм, Ширина: ${item.selectedWidth} мм`}
                                {item.additionalPriceChecked.length > 0 ?
                                    `, Додатково: ${item.additionalPriceChecked.map(add => add.additionally).join(', ')}`
                                    : ''}
                            </TableCell>
                            <TableCell style={{ width: '120px' }}>
                                <IconButton onClick={() => decrementQuantity(item)}><RemoveIcon /></IconButton>
                                {item.quantity}
                                <IconButton onClick={() => incrementQuantity(item)}><AddIcon /></IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => removeItemFromBasket(item.uniqueId)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

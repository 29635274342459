import {Grid} from "@mui/material";
import { styled, Typography } from '@mui/material';
import { css } from '@mui/system';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';


export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 0;
  margin-bottom: 20px;
`;

export const StyledSection = styled(Grid)`
  padding: 5px 10px ;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 4px;
  box-sizing: border-box;
`;

export const WrapperSectionContent = styled(Grid)`
  padding: 5px 10px ;
`

export const ProductBlockContainer = styled(Grid)`
  
`




export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const StyledCurrency = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontSize: '14px',
    marginLeft: '4px',
    alignItems: 'center',
    display: 'flex',
}));

export const StyledTypographyPrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 24px;
`;


export const StyledButtonAddToShoppingCard = styled(({ isMainState, ...otherProps }) => (
    <LoadingButton {...otherProps} />
))`
    margin-left: 12px;
    background: ${({ theme, isMainState }) =>
            isMainState ? theme.palette.secondary.dark : theme.palette.primary.main};
    &:hover {
        background-color: ${({ theme, isMainState }) =>
                isMainState ? theme.palette.secondary.main : theme.palette.secondary.dark} !important;
    }
`;



import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { LabelCheckbox, TitleFilter, WrapperTitleFilter } from "./styledComponents";
import Tooltip from '@mui/material/Tooltip';

export const Filter = ({ configKeys, filters, setCheckedFilters, checkedFilters }) => {
    const theme = useTheme();
    const handleChange = (key, variant) => (event) => {
        const currentKeyFilters = checkedFilters[key] || [];
        let newFilters;
        if (event.target.checked) {
            newFilters = { ...checkedFilters, [key]: [...currentKeyFilters, variant] };
        } else {
            newFilters = { ...checkedFilters, [key]: currentKeyFilters.filter((item) => item !== variant) };
        }
        setCheckedFilters(newFilters); // передаємо новий об'єкт стану
    };

    return (
        <WrapperTitleFilter>
            {configKeys && configKeys.map(key => (
                <div key={key}>
                    <TitleFilter theme={theme}>{filters[key].type}</TitleFilter>
                    <div>
                        {filters[key]?.variants?.map(variant => (
                            <FormControlLabel
                                key={variant}
                                control={
                                    <Checkbox
                                        color="secondary"
                                        checked={checkedFilters[key]?.includes(variant) || false}
                                        onChange={handleChange(key, variant)}
                                    />
                                }
                                label={
                                    <Tooltip title={variant} arrow>
                                        <LabelCheckbox theme={theme}>{variant}</LabelCheckbox>
                                    </Tooltip>
                                }
                                sx={{ display: 'flex', height: '28px', marginLeft: '0', marginRight: '0', textWrap: 'nowrap' }}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </WrapperTitleFilter>
    );
};
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate, useParams} from "react-router-dom";
import {Drawer, DrawerHeader, StyledListItemButton, StyledTypography} from "./drawer";
import { navList } from '../../../helper/constants'


export const Nav = ({ handleDrawerClose, open }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const params = useParams();

    const handleListItemClick = (e, param) => {
        navigate(`/admin/${param}`);
    };

    return (
            <Drawer theme={theme} variant="permanent" open={open} sx={{ color: theme.palette.secondary.dark }}>
                <DrawerHeader theme={theme}>
                    <StyledTypography variant="h6">
                        Категорії
                    </StyledTypography>
                    <IconButton onClick={handleDrawerClose}>
                        {
                            theme.direction === 'rtl'
                                ? <ChevronRightIcon sx={{ color: theme.palette.secondary.dark }} />
                                : <ChevronLeftIcon sx={{ color: theme.palette.secondary.main }} />
                        }
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List component="nav" aria-label="main">
                    {navList.map((item) => (
                        <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
                            <StyledListItemButton
                                palette={theme.palette}
                                selected={params.page === item.page}
                                onClick={(event) => handleListItemClick(event, item.page)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    color: theme.palette.secondary.contrastText,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: 'inherit',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                            </StyledListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
            </Drawer>
    );
}
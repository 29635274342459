import React, { useState } from 'react';
import { TextField, Button, IconButton, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ulStyle = {
    listStyleType: 'none',
    padding: 0,
    width: "100%",
};
const listStyle = {
    padding: 0,
    width: "100%",
    maxHeight: '30vh', // Встановлюємо висоту ul до половини висоти сторінки
    overflowY: 'scroll', // Дозволяємо прокручувати вміст ul
};
export const PricesList = ({ prices, setPrices, additional, isPriceValid= true, setIsPriceValid }) => {
    const [newWidth, setNewWidth] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [error, setError] = useState(false);

    const handleAddPrice = () => {
        if (newWidth && newPrice) {
            if (additional){
                // Перевірка наявності елементу з такою ж шириною
                const exists = prices.some(price => price.additionally === newWidth);

                if (exists) {
                    setError(true)
                    return;
                }

                const newPriceItem = {
                    additionally: newWidth,
                    price: newPrice
                };
                setPrices([...prices, newPriceItem]);
                setError(false)

            } else {
                // Перевірка наявності елементу з такою ж шириною
                const exists = prices.some(price => Number(price.width) === Number(newWidth));
                if (exists) {
                    setError(true)
                    return;
                }

                const newPriceItem = {
                    width: newWidth,
                    price: newPrice
                };
                setPrices([...prices, newPriceItem]);
                setError(false)
                // setIsPriceValid(true)
            }



            setNewWidth('');
            setNewPrice('');
        }
    };

    const handleDeletePrice = (index) => {
        const newPrices = [...prices];
        newPrices.splice(index, 1);
        setPrices(newPrices);
    };

    const handleEditPrice = (index, key, value) => {
        const newPrices = [...prices];
        newPrices[index] = {
            ...newPrices[index],
            [key]: value
        };
        setPrices(newPrices);
    };

    const textFieldStyle = { margin: '0 8px 0 0', width: '40%' }; // Встановлюємо відступ для текстового поля
    const textFieldPriceStyle = { margin: '0 0 0 0' }; // Встановлюємо відступ для текстового поля

    const inputContainerStyle = { display: 'flex', justifyContent: 'left' }; // Вирівнюємо інпути горизонтально

    // const mainContainerStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' }; // Вирівнюємо головний контейнер вертикально

    return (
        <ul style={ulStyle}>
            <div style={listStyle}>
                {prices.map((price, index) => (
                    <ListItem key={index} component="li" sx={{padding: 0.2, right: 0}}>
                        <ListItemText
                            primary={
                                <div style={inputContainerStyle}>
                                    <TextField
                                        style={textFieldStyle}
                                        label={`${additional ? 'Опція' : 'Ширина'} `}
                                        size="small"
                                        value={additional ? price.additionally : price.width}
                                        onChange={(e) => handleEditPrice(index, 'width', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                    />
                                    <TextField
                                        style={textFieldPriceStyle}
                                        label="Ціна"
                                        size="small"
                                        value={price.price}
                                        onChange={(e) => handleEditPrice(index, 'price', e.target.value)}
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                    />
                                </div>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleDeletePrice(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                <ListItem component="li" sx={{padding: 0, right: 0}}>
                    <ListItemText
                        primary={
                            <div style={inputContainerStyle}>
                                <TextField
                                    error={error || !isPriceValid}
                                    helperText={`${error ? additional ? 'Опція вже існує' : 'Ширина вже існує' : !isPriceValid ? 'Додайте ширину та ціну' : ''} `}
                                    style={textFieldStyle}
                                    label={`${additional ? 'Опція' : 'Ширина'} `}
                                    size="small"
                                    value={newWidth}
                                    onChange={(e) => setNewWidth(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                />
                                <TextField
                                    error={error || !isPriceValid}
                                    style={textFieldPriceStyle}
                                    label="Ціна"
                                    size="small"
                                    value={newPrice}
                                    onChange={(e) => setNewPrice(e.target.value)}
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                />
                            </div>
                        }
                    />
                    <ListItemSecondaryAction style={{ marginTop: '-10px'}} >
                        <IconButton disabled >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>


                </ListItem>
            </div>
            <Button sx={{marginBottom: '20px'}} onClick={handleAddPrice}>
                {`${additional ? 'Додати опцію / ціну' : 'Додати ширину / ціну'} `}
            </Button>

        </ul>
    );
};

import {FormHelperText, Grid, InputAdornment, TextField} from "@mui/material";
import {StyledTypographyFilter} from "@features/shared/styledComponents";
import React from "react";
import {useTheme} from "@mui/material/styles";


export const TextFieldInput = ({
            selectedLength,
            handleLengthChange,
            lengthError,
            getSelectedLengthError,
            title
    }) => {
    const theme = useTheme();

    return <Grid>
        <StyledTypographyFilter>{title}</StyledTypographyFilter>
        <TextField
            size="small"
            sx={{
                minWidth: 130,
                maxWidth: 130,
                '.MuiOutlinedInput-root': { height: '30px' },
                '.MuiInputBase-input': { color: theme.palette.primary.main }
            }}
            type="number"
            value={selectedLength}
            onChange={handleLengthChange}
            variant="outlined"
            error={Boolean(lengthError)}
            inputProps={{
                min: 300,
                max: 6000,
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">мм</InputAdornment>,
            }}
        />
        <FormHelperText error={Boolean(lengthError)}>
            {Boolean(lengthError) && getSelectedLengthError(selectedLength)}
        </FormHelperText>
    </Grid>
}
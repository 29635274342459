import React, { useState } from 'react';
import { TextField, MenuItem, Select } from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";



export const Price = ({options, setOptions}) => {


    const [selectedWidth, setSelectedWidth] = useState(options[0].width || []);
    const [selectedPrice, setSelectedPrice] = useState(options[0].price || []);


    const handleWidthChange = (event) => {
        const selectedWidth = event.target.value;
        setSelectedWidth(selectedWidth);

        const selectedOption = options.find((o) => o.width === selectedWidth);
        if (selectedOption) {
            setSelectedPrice(selectedOption.price);
        }
    };

    const handlePriceChange = (event) => {
        setSelectedPrice(event.target.value);

        const selectedOptionIndex = options.findIndex((o) => o.width === selectedWidth);
        if (selectedOptionIndex !== -1) {
            const newOptions = [...options];
            newOptions[selectedOptionIndex] = { ...newOptions[selectedOptionIndex], price: event.target.value };
            setOptions(newOptions);
        }
    };

    return (
        <div>


            <FormControl sx={{ minWidth: 195/2 }} >
                <InputLabel id="demo-simple-select-label">Ширина</InputLabel>
                    <Select label="Ширина" size="small" value={selectedWidth} onChange={handleWidthChange}>
                        {options.map((option) => (
                            <MenuItem key={option.width} value={option.width}>
                                {option.width}
                            </MenuItem>
                        ))}
                    </Select>
            </FormControl>

            <TextField
                label="Ціна"
                size="small"
                value={selectedPrice}
                onChange={handlePriceChange}
                type="number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
            />
        </div>
    );
}
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
});

export const reorderProducts = createAsyncThunk(
    'windowsill/reorder',
    async (productOrder, {dispatch, rejectWithValue }) => {
        try {
            const response = await api.put('/windowsill/products/reorder', { productOrder });
            dispatch(fetchWindowsillProducts());
            return response.data;

        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const createWindowsillProduct = createAsyncThunk(
    'windowsill/createProduct',
    async (product, { rejectWithValue }) => {
        try {
            // Send a POST request to the server to create a new product
            const response = await api.post('/windowsill/products', product);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateWindowsillProduct = createAsyncThunk(
    'windowsill/updateProduct',
    async (updateProduct, { rejectWithValue }) => {
        const { id, formData } = updateProduct;
        if (!id || !formData) {
            return rejectWithValue('Invalid product object: missing id or formData');
        }

        try {
            const response = await api.put(`/windowsill/products/${id}`, formData, );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchWindowsillProducts = createAsyncThunk(
    'windowsill/fetchProducts',
    async () => {
        const response = await api.get('/windowsill/products');
        const products = response.data.map(product => {
            const { _id, config, ...productWithoutConfig } = product;
            const configKeys = Object.keys(config);
            const newConfig = configKeys.map(key => ({
                key,
                ...config[key]
            }));
            return { ...productWithoutConfig, _id, config: newConfig };
        });

        // Сортування за полем "position"
        products.sort((a, b) => a.position - b.position);

        return products;
    }
);

export const fetchWindowsillProduct = createAsyncThunk(
    'windowsill/fetchProduct',
    async (id) => {
        const response = await api.get(`/windowsill/products/${id}`);
        const product = response.data;
        return product;
    }
);

export const deleteWindowsillProduct = createAsyncThunk(
    'windowsill/deleteProduct',
    async (productId, { rejectWithValue }) => {
        try {
            // Викликаємо DELETE запит для видалення продукту за ID
            const response = await api.delete(`/windowsill/products/${productId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchWindowsillFilters = createAsyncThunk(
    'windowsill/fetchFilters',
    async () => {
        const response = await api.get('/windowsill/filters');

        const updatedData = {};
        for (const [key, value] of Object.entries(response.data)) {
            const { _id, ...rest } = value;
            updatedData[key] = rest;
        }

        return updatedData;
    }
);

export const updateWindowsillFilters = createAsyncThunk(
    'windowsill/updateFilters',
    async ({ field, variants }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/windowsill/filters/${field}/variants`, { variants });
            const { category, __v, _id, ...filteredData } = response.data;
            return filteredData;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
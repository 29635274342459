import React from 'react';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import {CardActionArea, CardHeader} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@helper/utils";
import {
    ProductInfo,
    StyledBox,
    StyledEmptyBox,
    AnimatedGridContainer,
    StyledTitle,
    WrapperProduct, StyledTitlePrice
} from './styledComponents';

export const ProductList = ({ products, category }) => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const handleProduct = id => {
        navigate(`/${category}/${id}`);
    }

    let itemsPerRow;
    if (width <= 600) {
        itemsPerRow = 1;
    } else if (width <= 960) {
        itemsPerRow = 2;
    } else {
        itemsPerRow = 4;
    }

    const tilesToAdd = (products.length % itemsPerRow) === 0 ? 0 : itemsPerRow - (products.length % itemsPerRow);
    return (
        <AnimatedGridContainer container spacing={2}>
            {products.map((product) => {
                if (product.empty) {
                    return (
                        <Grid key={`empty-${Math.random()}`} item xs={12} sm={6} lg={3}>
                            <StyledEmptyBox
                                sx={{
                                    width: '100%',
                                    height: '352px',
                                    background: '#edeff1a6',
                                    borderRadius: '4px',
                                    paddingLeft: 0,
                                }}
                            />
                        </Grid>
                    );
                }
                const image = product?.image?.url;
                const name = product.name;
                const startPrice = product?.price[0]?.price;
                const configs = Object.values(product.config).filter(config => config.variant !== "None" && config.variant !== "");
                return (
                    <WrapperProduct key={product._id} item xs={12} sm={6} lg={3}>
                        <StyledBox>
                            <Card onClick={() => handleProduct(product._id)}>

                                <CardActionArea>
                                    <CardHeader

                                        title={name}
                                        // subheader = {`від ${startPrice} грн.`}
                                        sx={{ height: '40px' }}
                                    />
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={image}
                                        alt=""
                                    />
                                    <CardHeader
                                        subheader = {`від ${startPrice} грн.`}
                                    />
                                    <ProductInfo className="product-info">
                                        {configs.map((config, index) => {
                                            const lastSymbol = index === configs.length - 1 ? '' : ',';
                                            return (
                                                <Typography key={config.variant} variant="body2" color="text.secondary" component="span">
                                                    <span style={{ fontWeight: 'bold' }}>{`${config.type}: `}</span>
                                                    {`${config.variant}${lastSymbol} `}
                                                </Typography>
                                            );
                                        })}
                                    </ProductInfo>
                                </CardActionArea>
                            </Card>
                        </StyledBox>
                    </WrapperProduct>
                )
            })}
            {itemsPerRow !== 1 && Array.from({ length: tilesToAdd }).map((_, index) => (
                <Grid key={`tile-${index}`} item xs={12} sm={6} lg={3}>
                    <StyledEmptyBox
                        sx={{
                            width: '100%',
                            height: '352px',
                            background: '#edeff1a6',
                            borderRadius: '4px',
                            paddingLeft: 0,
                        }}
                    />
                </Grid>
            ))}
        </AnimatedGridContainer>
    );
};

export default ProductList;
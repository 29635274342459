import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const Properties = ({ config, setVariant, variant }) => {
    const params = useParams();
    const category = params.page;
    const variants = useSelector((state) => state[category]?.filters);

    const productVariant = (key) => {
        if (!variants) return [];
        const variant = variants[key];
        return variant ? variant.variants : [];
    };

    const handleChangeVariant = (event, type) => {
        const updateVariant = Object.values(variant).map((item) => {
            if (item.type === type) {
                return {
                    ...item,
                    variant: event.target.value,
                };
            } else {
                return item;
            }
        });
        setVariant(updateVariant);
    };

    if (!variants) {
        return <div>Завантаження...</div>;
    }

    return (
        <Box>
            <Grid container spacing={2} mb={3}>
                {config.map((property, index) => (
                    <Grid key={property.type} item>
                        <FormControl sx={{ minWidth: 195 }} size="small">
                            <InputLabel id={`select-${property.type}`}>{property.type}</InputLabel>
                            <Select
                                labelId={`select-${property.type}`}
                                id={`select-${property.type}2`}
                                label={property.type}
                                value={Object.values(variant)[index]?.variant || ''}
                                defaultValue={property.variant}
                                onChange={(event) => handleChangeVariant(event, property.type)}
                            >
                                {productVariant(Object.keys(variants)[index]).map((variant) => (
                                    <MenuItem key={variant} value={variant}>
                                        {variant}
                                    </MenuItem>
                                ))}
                                <MenuItem value=''>
                                    None
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

export const EditFilters = ({ filters = [], variants, setVariants, currentFilter, setCurrentFilter }) => {

    const filtersType = filters && Object.keys(filters);
    // const [currentFilter, setCurrentFilter] = useState('');
    const [newVariant, setNewVariant] = useState('');

    const handleType = (event) => {
        setCurrentFilter(event.target.value);
    };

    const handleAddVariant = () => {
        setVariants([...variants, newVariant]);
        setNewVariant('');
    };

    const handleDeleteVariant = (index) => {
        const updatedVariants = [...variants];
        updatedVariants.splice(index, 1);
        setVariants(updatedVariants);
    };

    const handleEditVariant = (index, value) => {
        const updatedVariants = [...variants];
        updatedVariants[index] = value;
        setVariants(updatedVariants);
    };

    const handleNewVariantChange = (event) => {
        setNewVariant(event.target.value);
    };


    useEffect(() => {
        if (currentFilter !== '') {
            setVariants(filters[currentFilter].variants);
        }
    }, [currentFilter]);

    return (
        filtersType && (
            <FormControl sx={{ minWidth: '100%', margin: '10px' }}>
                <InputLabel id="demo-simple-select-label">Фільтр</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentFilter}
                    onChange={handleType}
                    label="Фільтр"
                >
                    {filtersType.map((filter) => (
                        <MenuItem key={filter} value={filter}>
                            {filters[filter].type}
                        </MenuItem>
                    ))}
                </Select>

                {currentFilter === '' ? null : (
                    <Box sx={{ margin: '20px 0'}}>
                        {variants.map((variant, index) => (
                            <Box sx={{ margin: '10px 0'}} key={index}>
                                <TextField
                                    value={variant}
                                    onChange={(e) => handleEditVariant(index, e.target.value)}
                                    variant="outlined"
                                    style={{width: '91%'}}
                                    size="small"
                                />
                                <IconButton onClick={() => handleDeleteVariant(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <TextField
                            style={{width: '60%'}}
                            value={newVariant}
                            onChange={handleNewVariantChange}
                            label="Новий варіант"
                            variant="outlined"
                            size="small"
                        />
                        <Button onClick={handleAddVariant} disabled={newVariant.trim() === ''}>
                            Додати варіант
                        </Button>


                    </Box>
                )}
            </FormControl>
        )
    );
};


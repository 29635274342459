import {useDispatch, useSelector} from "react-redux";
import {StyledContainer} from "./styledComponents";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {StyledTypographyFilter} from "@features/shared/styledComponents";
import {StyledSection} from "@features/shared/ProductBlock/styledComponents";
import {BasketTable} from "@features/shared/BasketTable";
import {BasketContactForm} from "@features/shared/BasketContactForm";
import {BasketPayment} from "@features/shared";
import {calculateProductsTotalPrice} from "@services/reducers/basketSlice";

export const Basket = () => {
    const basketItems = useSelector(state => state.basket.items);
    const totalPrice = useSelector(state => state.basket.totalPrice); // Access totalPrice from Redux state
    const deliveryCharge = useSelector((state) => state.basket.deliveryCharge);

    const { state } = useLocation();
    const navigate = useNavigate();

    const from = state?.from || '/mosquitoNets';

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(calculateProductsTotalPrice())
    }, [basketItems, deliveryCharge])


    const handleBackToList = () => navigate(from)
    return  <StyledContainer>
        {basketItems.length === 0 ? (
            <div>
                <StyledTypographyFilter style={{ fontStyle: "italic" }}>
                    <ShoppingCartIcon /> Кошик порожній
                </StyledTypographyFilter>
                <Button startIcon={<ArrowBackIcon />} onClick={handleBackToList}>Продовжити покупки</Button>
            </div>
        ) : (
            <div>
                <Button startIcon={<ArrowBackIcon />} onClick={handleBackToList}>Список товарів</Button>
                <StyledSection sx={ {
                    marginTop: '10px',
                    marginBottom: '10px',
                }} item xs={12}>Кошик</StyledSection>
                <BasketTable />
                <StyledSection sx={ {
                    marginTop: '10px',
                    marginBottom: '10px',
                }} item xs={12}>Ваші контактні дані</StyledSection>
                <BasketContactForm />
                <StyledSection sx={ {
                    marginTop: '10px',
                    marginBottom: '10px',
                }} item xs={12}>Оплата</StyledSection>
                <BasketPayment  />
            </div>
        )}
    </StyledContainer>
}
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// Отримання стану користувача з localStorage
const userFromLocalStorage = JSON.parse(localStorage.getItem('user')) || {
    isLoggedIn: false,
    email: '',
    token: '',
    error: '',
    role: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState: userFromLocalStorage,
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.email = action.payload.email;
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.error = '';
            // Збереження стану в localStorage
            localStorage.setItem('user', JSON.stringify(state));
        },
        loginFailure: (state, action) => {
            state.isLoggedIn = false;
            state.email = '';
            state.token = '';
            state.role = '';
            state.error = action.payload;
            localStorage.removeItem('user');
        },
        loginClearFailure: (state) => {
            state.error = '';
        },
        logout: (state) => {
            Cookies.remove('token');
            state.isLoggedIn = false;
            state.email = '';
            state.token = '';
            state.role = '';
            state.error = '';
            localStorage.removeItem('user');
        },
        fetchUsersStart: (state) => {
            state.status = 'loading';
        },
        fetchUsersSuccess: (state, action) => {
            state.status = 'succeeded';
            state.users = action.payload;
        },
        fetchUsersFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const { fetchUsersStart, fetchUsersSuccess, fetchUsersFailure } = userSlice.actions;
export const { loginSuccess, loginFailure, logout, loginClearFailure } = userSlice.actions;

export default userSlice.reducer;

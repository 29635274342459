import React from 'react';
import { TextField, Grid, TextareaAutosize, useTheme, useMediaQuery } from '@mui/material';

export const BasketContactForm = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <form>
            <Grid container spacing={4}>

                <Grid container item xs={12} sm={6} spacing={1}>

                    {/* Ім'я */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField size="small" label="Ім'я" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                    {/* Прізвище */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField size="small" label="Прізвище" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                    {/* Мобільний телефон */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField size="small" label="Мобільний телефон" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
                        <TextField size="small" label="Електронна пошта" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                </Grid>

                <Grid container item xs={12} sm={6} spacing={1}>

                    {/* Адреса */}
                    <Grid item xs={12}>
                        <TextField size="small" label="Адреса" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                    {/* Коментар */}
                    <Grid item xs={12}>
                        <TextField size="small" label="Коментар" variant="outlined" fullWidth style={{ fontSize: '0.8rem' }} />
                    </Grid>

                </Grid>

            </Grid>
        </form>
    );
};

import { Button, Grid, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
    updateWindowsillFilters,
    fetchWindowsillFilters,
    fetchWindowsillProducts,
} from "@services/queries/windowsill";
import { useDispatch, useSelector } from "react-redux";
import { EditFilters } from "../shared";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
    fetchMosquitoNetsFilters,
    fetchMosquitoNetsProducts,
    updateMosquitoNetsFilters,
} from "@services/queries/mosquitoNets";
import {
    fetchDrainagesFilters,
    fetchDrainagesProducts,
    updateDrainagesFilters,
} from "@services/queries/drainages";
import {
    fetchAccessoriesFilters,
    fetchAccessoriesProducts,
    updateAccessoriesFilters,
} from "@services/queries/accessories";
import { useParams } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
};

const categoryFetchActions = {
    windowsill: fetchWindowsillFilters,
    mosquitoNets: fetchMosquitoNetsFilters,
    drainages: fetchDrainagesFilters,
    accessories: fetchAccessoriesFilters,
    // Add other categories if needed
};

const categoryUpdateActions = {
    windowsill: updateWindowsillFilters,
    mosquitoNets: updateMosquitoNetsFilters,
    drainages: updateDrainagesFilters,
    accessories: updateAccessoriesFilters,
    // Add other categories if needed
};

export const EditFiltersModal = ({ open, handleClose }) => {
    const params = useParams();

    const dispatch = useDispatch();
    const filters = useSelector((state) => state[params.page]?.filters);
    const loadingFilters = useSelector((state) => state[params.page]?.status);
    const [variants, setVariants] = useState([]);
    const [currentFilter, setCurrentFilter] = useState('');

    const resetState = () => {
        setCurrentFilter('');
        setVariants([]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (variants.length > 0 && currentFilter !== '') {
            if (categoryUpdateActions[params.page]) {
                dispatch(categoryUpdateActions[params.page]({ variants, field: currentFilter }));
            }
        }
        resetState();
        handleClose();
    };

    useEffect(() => {
        if (categoryFetchActions[params.page]) {
            dispatch(categoryFetchActions[params.page]());
        }
    }, [dispatch, params.page]);

    useEffect(() => {
        if (loadingFilters === 'loading') {
            return;
        }
    }, [loadingFilters]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box component="form" onSubmit={handleSubmit} sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Редагувати фільтра
                </Typography>
                {loadingFilters === 'loading' ? 'Loading...' : (
                    <EditFilters
                        setCurrentFilter={setCurrentFilter}
                        currentFilter={currentFilter}
                        variants={variants}
                        setVariants={setVariants}
                        filters={filters}
                    />
                )}
                <Grid item display='flex' justifyContent='center' sx={{ marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="success">
                        Оновити фільтр <ReceiptIcon sx={{ marginLeft: 1 }} />
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

import { createSlice } from '@reduxjs/toolkit';
import {
    createMosquitoNetsProduct,
    deleteMosquitoNetsProduct,
    fetchMosquitoNetsFilters,
    fetchMosquitoNetsProducts,
    reorderMosquitoNetsProducts,
    updateMosquitoNetsFilters,
    updateMosquitoNetsProduct,
    fetchMosquitoNetsProduct
} from "../../queries/mosquitoNets";

const mosquitoNetsSlice = createSlice({
    name: 'mosquitoNets',
    initialState: {
        checkedFilters: {},
        products: [],
        product: null,
        filters: null,
        status: 'loading',
        statusProduct: 'loading',
        error: null,
    },
    reducers: {
        setMosquitoNetsCheckedFilters: (state, action) => {
            state.checkedFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMosquitoNetsProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMosquitoNetsProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchMosquitoNetsProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchMosquitoNetsProduct.pending, state => {
                state.statusProduct = 'loading';
            })
            .addCase(fetchMosquitoNetsProduct.fulfilled, (state, action) => {
                state.statusProduct = 'succeeded';
                state.product = action.payload;
            })
            .addCase(fetchMosquitoNetsProduct.rejected, (state, action) => {
                state.statusProduct = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchMosquitoNetsFilters.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMosquitoNetsFilters.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.filters = action.payload;
            })
            .addCase(fetchMosquitoNetsFilters.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteMosquitoNetsProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteMosquitoNetsProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = state.products.filter(product => product._id !== action.payload._id);
            })
            .addCase(deleteMosquitoNetsProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createMosquitoNetsProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createMosquitoNetsProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { _id, config, ...productWithoutConfig } = action.payload.product;
                const newProduct = { ...productWithoutConfig, _id, config };
                state.products.push(newProduct);
            })
            .addCase(createMosquitoNetsProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateMosquitoNetsProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateMosquitoNetsProduct.fulfilled, (state, action) => {
                const updatedProduct = action.payload.mosquitoNetsProduct;
                const updatedProducts = state.products.map((product) =>{
                    return product._id === updatedProduct._id ? updatedProduct : product
                });

                return {
                    ...state,
                    status: 'succeeded',
                    products: updatedProducts,
                };
            })
            .addCase(updateMosquitoNetsProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(reorderMosquitoNetsProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(reorderMosquitoNetsProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(reorderMosquitoNetsProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateMosquitoNetsFilters.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMosquitoNetsFilters.fulfilled, (state, action) => {
                state.loading = false;
                state.filters = action.payload;
            })
            .addCase(updateMosquitoNetsFilters.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setMosquitoNetsCheckedFilters } = mosquitoNetsSlice.actions;
export default mosquitoNetsSlice.reducer;

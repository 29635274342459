import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";


export const LabelCheckbox = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px; // Ви можете налаштувати це значення відповідно до ваших потреб
`;
export const TitleFilter = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 4px 10px;
  border-radius: 4px;
  margin: 10px 0 10px 0;
`;



export const WrapperTitleFilter = styled.div`
  width: 80%;
    min-width: 100px;
  animation: slideFromLeft 0.6s ease-out;

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;



import {drawerWidth} from "../../../../helper/constants";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-right: auto;
`;


export const StyledListItemButton = styled(ListItemButton)`
  && {
    background-color: ${({ selected, palette }) => selected ? palette.secondary.main : 'transparent'};
    color: ${({ selected, palette }) => selected ? palette.secondary.contrastText : palette.primary.contrastText};

    &:hover {
      background-color: ${({ selected, palette }) => !selected ? palette.secondary.main : 'none'};
      opacity: ${({ selected }) => selected ? 'none' : 0.6};

    }
  }
`;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,

    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '12px',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.background.default,
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Element, ...rest }) => {
    const { isLoggedIn, role } = useSelector(state => state.user);
    return isLoggedIn && role === 'admin' ? (
        <Element {...rest} />
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtectedRoute;
